// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
// Variables
@import "variables";
// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
#map {
    height: 100%;
    width: 100%;
    min-height: 300px;
    margin-bottom: 20px;
}

#legend {
    border: 1px solid #333 !important;
    background: white;
    padding: 4px;
    margin: 4px;
    display: none;
}

#legend h3 {
    font-size: 14px;
    margin: 4px;
}

#legend div {
    padding: 3px;
}

#legend img {
    width: 18px;
    height: 18px;
}

#search-results {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}